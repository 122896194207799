<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header card-header-stretch">
        <h3 class="card-title fw-bolder text-gray-800 fs-2">Aktywne aplikacje</h3>
        <div class="card-toolbar m-0">
  				<ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
  					<li @click="type='now'" class="nav-item" role="presentation">
  						<a :class="type=='now' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Teraz</a>
  					</li>
  					<li @click="type='today'" class="nav-item" role="presentation">
  						<a :class="type=='today' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Dzisiaj</a>
  					</li>
  					<li @click="type='7d'" class="nav-item" role="presentation">
  						<a :class="type=='7d' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">7 dni</a>
  					</li>
  					<li @click="type='1m'" class="nav-item" role="presentation">
  						<a :class="type=='1m' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">Miesiąc</a>
  					</li>
  					<li @click="type='3m'" class="nav-item" role="presentation">
  						<a :class="type=='3m' ? 'active' : ''" class="nav-link justify-content-center text-active-gray-800" role="tab">3 miesiące</a>
  					</li>
  				</ul>
			   </div>
      </div>
      <div class="card-body py-0">
        <div class="table-responsive">
          <table class="table align-middle table-row-bordered table-row-dashed gy-5">
            <tbody>
              <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                <th class="min-w-125px px-0">Aplikacja</th>
                <th class="min-w-100px text-center">Zapytań</th>
                <th class="min-w-125px">Konto klienta</th>
              </tr>
              <tr v-for="stat in stats">
                <td class="p-0 align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <router-link :to="{ name: 'AdminAppsDetails', params: { id: stat.organisation_id, appid: stat.id }}" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ stat.name }}
                      </router-link>
                      <span class="text-gray-400 fw-bold d-block">
                        {{ stat.id }}
                      </span>
                    </div>
                  </div>
                </td>
                <td class="align-top pt-5 pb-5 text-center">
                  {{ stat.counter }}
                </td>
                <td class="align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    {{ stat.organisation_name }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stats',
  data() {
    return {
      type: 'today',
      stats: []
    }
  },
  mounted () {
      this.reload();
      this.timer = setInterval(this.reload, 600000);
  },
  methods: {
    reload: function() {
      var self = this;
      this.$root.$refs.api.get('stats?type='+this.type).then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.stats = response.data;
        }
      })
      .catch(function (error) {
        self.stats = [];
        console.log(error);
      });
    }
  },
  watch: {
    type: function() {
      this.reload();
    }
  }
};
</script>
